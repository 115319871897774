import React from 'react';
import CheckMark from '../Icons/CheckMark';
import { exchangeRates } from '../../../data/SiteConfig';

const oneGbpInUsd = exchangeRates.gbpToUsdRate.toFixed(2);

const planItems = {
  liveGroup: [
    'All 6 modules of video content',
    '4x weekly office hours',
    'Private Slack channel',
    'GitHub repository with sample application',
    'Testing cheat sheets',
    '1-year access to content, including all updates',
    'Access to office hours recordings',
  ],
  livePersonal: [
    'Everything from Group Office Hours',
    '2x1hr private 1-on-1 coaching sessions with Paul',
  ],
};

const formatPlanItems = (items) =>
  items.map((i) => (
    <li className="flex items-start">
      <div className="flex-shrink-0">
        <CheckMark />
      </div>
      <p className="ml-3 text-base text-gray-700 my-0">{i}</p>
    </li>
  ));

export default function WorkshopPricingPlans({ workshopConfig }) {
  const isEarlyBirdActive =
    new Date(workshopConfig.nextWorkshopEarlybirdExpiryTime).toISOString() >
    new Date().toISOString();
  console.log(
    `isEarlyBirdActive=${isEarlyBirdActive}, nextWorkshopEarlybirdExpiryTime=${workshopConfig.nextWorkshopEarlybirdExpiryTime}`
  );
  const prices = workshopConfig.prices[workshopConfig.activeCurrency];
  const cur = workshopConfig.activeCurrencySymbol;

  const getSignupUrl = (productKey) => {
    const signupUrl = workshopConfig.signupUrls[productKey];
    const couponCode = isEarlyBirdActive
      ? workshopConfig.earlybirdCouponCodes[productKey]
      : undefined;
    return `${signupUrl}${couponCode ? `?coupon=${couponCode}` : ''}`;
  };

  const renderPrice = (fullPrice) => {
    const fullPriceDisplay = fullPrice.toLocaleString('en-GB');
    const currentPrice = isEarlyBirdActive
      ? (
          fullPrice *
          (1 - workshopConfig.earlybirdDiscountPercent / 100)
        ).toLocaleString('en-GB', { maximumFractionDigits: 2 })
      : fullPriceDisplay;
    if (!isEarlyBirdActive) {
      return (
        <div className="text-6xl font-extrabold">
          <span>
            {cur}
            {fullPriceDisplay}
          </span>
        </div>
      );
    }
    return (
      <div>
        <div className="text-6xl font-extrabold">
          <span className="line-through opacity-25 text-4xl">
            {cur} {fullPriceDisplay}
          </span>{' '}
          <span>
            {cur}
            {currentPrice}
          </span>
        </div>
        <div className="text-warmGray-500 text-lg italic">
          {workshopConfig.earlybirdDiscountPercent}% early bird discount until{' '}
          {workshopConfig.nextWorkshopEarlybirdDeadline}
        </div>
      </div>
    );
  };
  return (
    <div className="bg-gray-900">
      <div className="pt-8 sm:pt-12 lg:pt-18" />
      <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-gray-900" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3
                      className="text-3xl font-semibold text-gray-900"
                      id="tier-standard"
                    >
                      Video Lessons + Office Hours
                    </h3>
                  </div>
                  <div className="mt-4 flex items-baseline">
                    {renderPrice(prices.liveGroup)}
                  </div>
                  <p className="mt-5 mb-0 text-lg text-gray-600">
                    4-week, self-paced video lessons with weekly office hours.
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                  <ul className="space-y-4">
                    {formatPlanItems(planItems.liveGroup)}
                  </ul>
                  <div className="rounded-md shadow">
                    <a
                      href={getSignupUrl('liveGroup')}
                      className="btn flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-700 hover:bg-primary-800"
                    >
                      Register now
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3
                      className="text-3xl font-semibold text-gray-900"
                      id="tier-premium"
                    >
                      Personalised Coaching
                    </h3>
                  </div>
                  <div className="mt-4 flex items-baseline">
                    {renderPrice(prices.livePersonal)}
                  </div>
                  <p className="mt-5 text-lg text-gray-600 mb-0">
                    Get customised expert help with applying testing strategies
                    to your own serverless project.
                  </p>
                </div>
                <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                  <ul className="space-y-4">
                    {formatPlanItems(planItems.livePersonal)}
                  </ul>
                  <div className="rounded-md shadow">
                    <a
                      href={getSignupUrl('livePersonal')}
                      className="btn flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-700 hover:bg-primary-800 hover:text-white"
                      aria-describedby="tier-standard"
                    >
                      Register now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-5xl">
            <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
              <div className="flex-1">
                <div>
                  <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                    Self-Study
                  </h3>
                </div>
                <div className="mt-4 text-lg text-gray-600">
                  <p>
                    Can’t make the live sessions and just want access to the
                    workshop materials?
                  </p>
                  <p>
                    Get immediate access to all the course videos, code and
                    exercises for{' '}
                    <span className="font-semibold text-gray-900">
                      {cur}
                      {prices.selfStudy.toLocaleString('en-GB')}
                    </span>
                    .
                  </p>
                </div>
              </div>
              <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                <a
                  href={getSignupUrl('selfStudy')}
                  className="btn flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                >
                  Buy Self-Study
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-5xl">
            <div className="px-6 py-1 lg:flex lg:items-center">
              <div className="flex-1">
                <div className="mt-4 text-base text-gray-600">
                  All prices are in GBP and exclusive of VAT. 1 GBP ≈{' '}
                  {oneGbpInUsd} USD.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
