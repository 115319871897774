import React from 'react';
import Img from 'gatsby-image'
import { graphql } from 'gatsby';
import Landing from '../../templates/landing';
import MetaTags from '../../components/MetaTags';
import { workshopConfig } from '../../../data/SiteConfig';
import WorkshopPricingPlans from '../../components/TestingWorkshop/WorkshopPricingPlans';

import Logo from '../../components/Logo';

const metaTitle = 'Serverless Testing Workshop';
const metaDescription = 'Remote workshop for learning how to effectively test your serverless applications on AWS';

const LOGIN_URL = 'https://serverlessfirst.podia.com/login';

const Page = ({ data }) => {
  const CtaBox = ({ showSignupNow = true }) => {
    return (
      <div>
        <div className="px-4 py-8 sm:px-10">
          <div className="mt-6">
            <div className="text-gray-800">
              <h4 className="text-base text-gray-600 uppercase font-medium pr-2 text-center">
                Next Cohort
              </h4>
              <p className="text-center">
                <span className="text-black font-bold">
                  {workshopConfig.nextWorkshopStartAndEndDate}
                </span>
              </p>
              <p className="text-base text-center">
                <span>Meetings every </span>
                <br />
                <span className="text-lg font-bold">{workshopConfig.meetingDayOfWeek}</span>
                <span> @ </span>
                <span className="text-lg font-bold">
                  {workshopConfig.meetingTimeMe}
                </span>
                <br />
                <span className="">
                  (
                  {workshopConfig.meetingTimeUsEastern}
                  )
                </span>
              </p>
              {showSignupNow && (
                <a href="#plans" className="btn w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Register now</a>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 py-2 sm:px-10">
          <p className="text-sm text-gray-500 text-center">
            <a className="text-gray-700" href={workshopConfig.everyTimezoneLink} target="_blank">Check your timezone</a>
          </p>
        </div>
      </div>
  )
  };

  const Testimonial = ({ author, jobRole, body, imgFilename }) => {
    return (
      <blockquote className="mt-6 md:flex-grow md:flex md:flex-col workshop-testimonial">
        <div className="relative text-lg font-medium text-white md:flex-grow">
          <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-primary-600" fill="currentColor" viewBox="0 0 32 32">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          {body}
        </div>
        <footer className="mt-8">
          <div className="flex items-start">

            <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
              <img className="h-12 w-12 rounded-full" src={`/img/testimonials/${imgFilename}`} alt={author} />
            </div>
            <div className="ml-4">
              <div className="text-base font-medium text-white">{author}</div>
              <div className="text-base font-medium text-gray-200">{jobRole}</div>
            </div>
          </div>
        </footer>
      </blockquote>
    )
  }
	return (
  <Landing>
    <MetaTags
      title={metaTitle}
      description={metaDescription}
      image={data.socialBannerImage.childImageSharp.fluid.src}
      twitterCardType="summary_large_image"
    />
    <article className="font-sans">
      <div className="relative bg-gray-900 overflow-hidden">
        <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
          <svg className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0" width="364" height="384" viewBox="0 0 364 384" fill="none">
            <defs>
              <pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
          </svg>
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <nav className="relative md:grid md:grid-cols-2 max-w-7xl mx-auto px-4 sm:px-6 text-center md:text-left">
            <div>
              <h1 className="text-xl font-bold text-white tracking-wide m-0 leading-7 uppercase">Serverless Testing Workshop</h1>
              <h2 className="text-base font-medium text-primary-200 m-0">with Paul Swail</h2>
            </div>
            <div className="text-center md:text-right">
              <a className="border-none text-sm underline text-gray-100" href={LOGIN_URL}>
                Student sign-in
              </a>
            </div>
          </nav>
          <main className="mt-16 sm:mt-24">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                      <span className="text-primary-400 md:block">Productive testing strategies </span>
                      <span className="md:block">for your serverless applications.</span>
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Learn how to build quality into your AWS backends without compromising on development velocity.
                    </p>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                  <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                    <CtaBox />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <section className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                “I need a faster feedback loop.”
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-700 leading-8">
              You chose serverless so you could move quickly. Not having to manage servers and outsourcing your scaling concerns to AWS frees up a large chunk of your time to focus on delivering real value for your users.
            </p>
          </div>
          <div className="mt-6 prose prose-primary prose-lg text-gray-700 mx-auto">
            <p>At least that’s the story you were told.</p>
            <p>But when it came to building out your first serverless application, you realised that this stuff still needs testing as there’s still a lot that can go wrong. And what’s more, your existing approaches to testing your code are no longer as effective.</p>
            <p>You could run local simulators of cloud-native services, but these aren’t like-for-like and many cloud services don’t have a local equivalent.</p>
            <p>So you start writing more integration tests against the real cloud services. But this is a slooow process. Having to redeploy your code changes to the cloud every time is a productivity killer.</p>
            <p>You can speed things up by mocking out all the cloud service calls and running everything locally. But now you’re missing out on catching the whole category of “configuration error” bugs (IAM permissions, anyone?). And given that most of your code now seems to be simply passing data between cloud services, are these unit tests worth the effort it takes to write them?</p>

            <h2>Professional engineers move fast while minimising breakages.</h2>
            <p>It’s hard to beat that warm feeling of assurance you get when pushing a PR for a new API endpoint with a comprehensive set of passing tests against a real cloud environment. When you know the chances of it breaking the CI build or of a frontend developer or real user finding a bug in it are miniscule.</p>
            <p>You’re a pragmatist and you understand that it’s impossible to write tests to cover every permutation. But how do you reach a sufficient level of confidence with a serverless architecture without burning hours or days on writing different types of tests?</p>

            <h2>That’s why I created the Serverless Testing Workshop</h2>
            <p>In the workshop, you’ll learn a framework for approaching testing of any serverless use cases you will encounter, along with concrete patterns for use with specific AWS services.</p>
            <p>After completing the workshop, you’ll understand:</p>
            <ul className="list-none">
              <li>
                How to identify test cases by applying an event-based approach to your feature scenarios
              </li>
              <li>Patterns for writing tests for commonly used AWS services such as Lambda, API Gateway, DynamoDB, EventBridge, SNS, SQS, Cognito, S3 and more</li>
              <li>How and when to deploy the different categories of test: unit, integration and end-to-end</li>
              <li>The typical failure modes that your automated tests should aim to detect</li>
              <li>How to structure your Lambda functions to make them more testable</li>
              <li>How to test configured behaviour that’s not defined inside a Lambda function</li>
              <li>Techniques for setting up and tearing down test data that integration tests rely upon</li>
              <li>How to manage configuration for your tests to maximise their reusability across developer testing and your deployment pipelines</li>
              <li>How to avoid flaky tests</li>
            </ul>
          </div>
        </div>
      </section>


      <section className="bg-gray-900">
        <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
          <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-primary-900 lg:pr-16">
            <Testimonial
              author="Kevin Xu"
              jobRole="Founder, Westside Labs"
              imgFilename="kevin-x.jpg"
              body={(
                <div className="relative">
                  <p>
                    I’m so glad I took this workshop!
                  </p>
                  <p><strong>I was able to apply many of the testing techniques as well as some larger architectural patterns to my professional and personal projects.</strong></p>
                  <p>Paul was a great teacher with deep knowledge of Serverless testing and applications as a whole.</p>
                  <p>The other students were also very engaged which led to lots of productive discussions during the sessions.</p>
                </div>
                )}
            />
          </div>
          <div className="py-12 px-4 border-t-2 border-primary-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
            <Testimonial
              author="Søren Andersen"
              jobRole="Independent full-stack developer"
              imgFilename="soren-a.jpg"
              body={(
                <div className="relative">
                  <p>
                    While the main focus of the workshop of course was testing - which was thoroughly delivered with great insight and immediately useful techniques - I also
                    {' '}
                    <strong>really enjoyed that we worked with a realistic and high quality sample codebase packed with typical architectural patterns</strong>
                    {' '}
                    including auth, queues, event buses and streams.
                  </p>
                  <p>I learned a lot from attending this workshop.</p>
                </div>
                )}
            />
          </div>
        </div>
      </section>

      <section className="px-8 py-8 bg-gray-100">
        <div className="max-w-4xl mx-auto text-center">
          <CtaBox showSignupNow={false} />
        </div>
      </section>

      <section id="plans">
        <WorkshopPricingPlans workshopConfig={workshopConfig} />
      </section>

      <section className="py-16">
        <div className="max-w-3xl px-4 mx-auto">
          <div className="md:flex gap-8">
            <div className="text-center w-full mx-auto flex-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-primary-200 w-16 text-center">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </div>
            <div>
              <h2 className="text-orange-700 text-4xl leading-tight mt-0">
                100% Money Back Guarantee
              </h2>
              <p>If you’re not satisfied with the workshop, you can take it again or ask for your money back within 30 days of completion and I’ll refund your full fee.</p>
            </div>
          </div>
        </div>
      </section>



      <section className="bg-gray-200 py-6 px-6">
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 ">

          <div className="max-w-xs md:max-w-full text-center">
            <Img fluid={data.paulImage.childImageSharp.fluid} className="rounded-full" />
          </div>
          <div className="md:col-span-2">
            <h3 className="text-2xl mt-0">Hi, I’m Paul Swail, your instructor for this workshop.</h3>
            <p>I’m the founder of Serverless First, where I help development teams make the transition to serverless within the AWS ecosystem. I write regularly about serverless and the opportunities and challenges that it brings.</p>
            <p>I’ve been a professional software developer/architect for almost 20 years and I’ve also been running a SaaS business on AWS since 2014.</p>
          </div>
        </div>
      </section>

      <section className="py-8">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-center uppercase tracking-wider text-warmGray-600">
            Curriculum
          </h2>
          <h3>Module 1: Fundamentals of software testing</h3>
          <ul>
            <li>Why we test</li>
            <li>Testing levels</li>
            <li>Where to run your tests</li>
          </ul>
          <h3>Module 2: Introduction to testing serverless applications</h3>
          <ul>
            <li>How testing serverless applications is different</li>
            <li>Structuring your test cases</li>
            <li>Failure modes and testing levels</li>
          </ul>
          <h3>Module 3: Setting up our test tooling</h3>
          <ul>
            <li>Introduction to Jest</li>
            <li>Structuring our codebase</li>
            <li>Interactive debugging in VSCode</li>
          </ul>
          <h3>Module 4: Testing synchronous flows</h3>
          <ul>
            <li>Integration testing an API Gateway Lambda handler - Part 1</li>
            <li>Integration testing an API Gateway Lambda handler - Part 2</li>
            <li>E2E testing an API Gateway endpoint</li>
            <li>Two tests for the price of one</li>
            <li>Testing an authenticated API Gateway endpoint</li>
          </ul>
          <h3>Module 5: Testing asynchronous flows</h3>
          <ul>
            <li>Challenges with asynchronous testing</li>
            <li>Consuming EventBridge events</li>
            <li>Publishing events to EventBridge</li>
            <li>Consuming S3 events</li>
          </ul>
          <h3>Module 6 - Batch Processing and Streaming</h3>
          <ul>
            <li>Processing batches in SQS</li>
            <li>Writing unit tests for an SQS handler</li>
            <li>Writing E2E tests for an SQS Handler</li>
            <li>Processing DynamoDB Streams</li>
            <li>Writing unit tests for a DynamoDB Stream handler</li>
          </ul>
        </div>
      </section>

      <section className="py-8">
        <div className="max-w-3xl px-4 mx-auto">
          <h2 id="faqs" className="text-orange-700 text-4xl">FAQs</h2>
          <h3>
            What are the pre-requisites for taking the workshop?
          </h3>
          <ul>
            <li>A basic knowledge of AWS</li>
            <li>Your own laptop or desktop computer</li>
            <li>A code editor/IDE and command line terminal. I will be using VSCode in the lessons, but you can use your preferred editor.</li>
            <li>Access to an AWS account that you have administrator privileges for (all resources used in the exercises should be covered by the AWS Free Tier)</li>
            <li>Webcam &amp; microphone for participation in weekly live sessions</li>
          </ul>
          <h3>How much time is this going to take?</h3>
          <p>
            Your average time commitment will be 3–4 hours per week. This includes time reviewing the videos, completing exercises and attending the weekly live session.
          </p>
          <h3>Will you be covering “testing in production” techniques?</h3>
          <p>
            No, this workshop is focused on helping developers write automated tests to detect defects before they get into production. While techniques and practices such as monitoring and chaos engineering can be very valuable to an engineering team, we don’t have the time to cover them in this course. More specialised forms of pre-production testing, such as load testing or penetration testing are also out of scope.
          </p>
          <h3>Will you be covering any frontend testing? </h3>
          <p>
            No. While some of the testing strategies and tools in the workshop can also be used in frontend development, we will be focusing entirely on backend workloads.
          </p>
          <h3>What programming runtime will you be using?</h3>
          <p>
            The code examples and exercises all use Node.js with TypeScript. However the patterns taught can be ported to other runtimes.
          </p>
          <h3>Do you offer Purchasing Power Parity?</h3>
          <p>
            If you can pay full price for the workshop in your local currency but not in GBP, then please let me know and I’ll calculate a PPP adjustment discount for you. Just email me at paul@serverlessfirst.com about wanting the PPP adjustment and tell me where you live and I’ll use something like the Big Mac Index to make the calculation and create a discount code for you to use when purchasing the workshop.
          </p>
          <h3>Do you offer a volume discount?</h3>
          <p>
            Yes. If you have 3 or more students to register, you’ll get 15% off. Just email me at paul@serverlessfirst.com to get a discount code.
          </p>
          <h3>Do you offer private workshops for an entire team?</h3>
          <p>
            Yes. If you wish to a group of engineers from your company, I can arrange to run the workshop privately (and remotely) for them. Just email me at paul@serverlessfirst.com to schedule this.
          </p>
          <h3>I have other questions about the workshop...</h3>
          <p>
            I’d love to help. Email me at paul@serverlessfirst.com.
          </p>
        </div>
      </section>
      <section className="px-8 py-8 bg-gray-100">
        <div className="max-w-xl mx-auto text-center">
          <CtaBox />
        </div>
      </section>
      <section className="text-center mx-auto mt-16">
        <Logo width="48" />
      </section>
    </article>
  </Landing>
	);
}


export const query = graphql`
  query {
    paulImage: file(relativePath: { eq: "paul_swail.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    socialBannerImage : file(relativePath: { eq: "blog-images/serverless-testing-workshop-banner-may-2021.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Page;
